export default function(str, proto) {
  const result = {};

  ('&' + (str.split('?', 2)[1] || '')).replace(
    /&(\w+)((?:\.\w+)+)=([^&]*)/g,
    (_, prefix, rest, value) => {
      let last = result;
      if(!proto || proto[prefix]) {
        rest.split('.').slice(1).forEach(
          prop => {
            if(/^\d+$/.test(prop))
              prop = +prop;
            last = last[prefix] || (
              last[prefix] = typeof prop=='number' ? [] : {}
            );
            prefix = prop;
          }
        );
        last[prefix] = value ? (+value==value) ? +value : value : null;
      }
      return '';
    }
  );

  return result;
}
