/* global System */

import loadAssets from './load-assets';

export default (path = '/') =>
  (name, noCss) =>
    Promise.all([
      window.mqConfig.bwCompat
        ? System.import(`${path}rp-assets/js/${window.mqScripts?.['extra.js'] || 'extra.js'}`)
        : import(`${path}rp-assets/js/${window.mqScripts?.['extra.mjs'] || 'extra.mjs'}`),
      noCss ||
        new Promise((resolve, reject) =>
          loadAssets(inject =>
            inject(
              'link',
              {
                rel: 'stylesheet',
                type: 'text/css',
                href: `${path}rp-assets/css/${window.mqStyles?.['extra.css'] || 'extra.css'}`
              },
              [resolve, reject]
            )
          )
        )
    ]).then(([module]) => module);
