import deepMerge from './deep-merge';

export default (configs = [], boot) => {
  const result = Object.create({push, get});

  configs.forEach(push);
  push({mq: {}, provider: {}});
  boot(result);

  return result;

  function push(config) {
    deepMerge(result, config);
  }

  function get(path, defaultValue) {
    let at = result;
    path.replace(/\w+/g, propName => {
      if(at)
        at = at[propName];
      return '';
    } );
    return at===undefined ? defaultValue : at;
  }
}

