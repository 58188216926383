// Given a mask with N rightmost 1s, returns the next available 1
const nextMaskBit = m => ((~m - 1) & ~m) ^ ~m;

export default boot => {
  let fullMask = 0,
    completionMask = 0,
    successMask = 0;

  const providers = {
    mapbox() {
      inject(
        'script',
        {
          src: 'https://static.mqcdn.com/packages/maplibre-gl@1.15.3/dist/maplibre-gl.js',
          crossorigin: true
        },
        assetReady(
          () =>
            (window.maplibregl.accessToken =
              'pk.eyJ1IjoibWFwcXVlc3QiLCJhIjoiY2wxZjJtZnR0MDhmcTNkbng0YXJ6NGRhOSJ9.MLAPXEtVvHEki3yBRXpivw')
        )
      );

      inject(
        'link',
        {
          rel: 'stylesheet',
          type: 'text/css',
          href: 'https://static.mqcdn.com/packages/maplibre-gl@1.15.3/dist/maplibre-gl.css'
        },
        assetReady()
      );
    }
  };

  const complete = boot(inject, assetReady, injectProviderScripts);

  function injectProviderScripts(provider) {
    provider = provider.map || (provider.map = 'mapbox');
    providers[provider]();
    return provider;
  }

  function inject(type, props, [onload, onerror] = []) {
    document.head.appendChild(Object.assign(document.createElement(type), props, {onload, onerror}));
  }

  function assetReady(onSuccess, altSuccess, makeErrorInfo) {
    const mask = nextMaskBit(fullMask),
      success = () => {
        if (onSuccess) onSuccess();
        check((completionMask |= mask), (successMask |= mask));
      };

    fullMask |= mask;

    if (altSuccess) altSuccess(success);

    return [altSuccess ? null : success, err => check((completionMask |= mask), makeErrorInfo && makeErrorInfo(err))];
  }

  function check(completion, successMaskOrErrorInfo) {
    if (completion == fullMask) complete(completion == successMaskOrErrorInfo, successMaskOrErrorInfo);
  }
};
