import loadAssets from './load-assets';

export default (...urls) => new Promise(
  (resolve, reject) => loadAssets(
    (inject, assetReady) => {
      urls.forEach(
        src => inject(
          'script', {src, crossorigin: /\/\//.test(src)},
          assetReady(0, 0, err => {
            err.message = err.message + ` in ${src}`;
            return err;
          })
        )
      );

      return (success, err) => (success ? resolve() : reject(err));
    }
  )
);
