/* global System */

import makeConfig from './make-config';
import loadAssets from './load-assets';
import fromQueryString from './query-string';
import loadOverlay from './load-overlay';
import loadScript from './load-script';

window.mqConfig = makeConfig(window.mqConfig, config => {
  const {push, mq, provider} = config;
  let href;

  if (!mq.noUrlParams) push(fromQueryString(location.search, config));

  const prerequisites = new Promise((resolve, reject) =>
    loadAssets((inject, assetReady, injectProviderScripts) => {
      const scripts = window.mqScripts || {};
      const styles = window.mqStyles || {};
      injectProviderScripts(provider);
      if (window.mqConfig.bwCompat) {
        href = `/rp-assets/js/${scripts['map-mapbox.js'] || 'map-mapbox.js'}`;

        inject('link', {
          rel: 'preload',
          as: 'script',
          href
        });
      } else {
        href = `/rp-assets/js/${scripts['map-mapbox.mjs'] || 'map-mapbox.mjs'}`;

        inject('link', {
          rel: 'modulepreload',
          href
        });
      }

      inject(
        'link',
        {
          rel: 'stylesheet',
          type: 'text/css',
          href: `/rp-assets/css/${styles['map.css'] || 'map.css'}`
        },
        assetReady()
      );

      return success => (success ? resolve() : reject(new Error('MapQuest components failed to load')));
    })
  );

  mq.mapReady = () => prerequisites.then(() => (window.mqConfig.bwCompat ? System.import(href) : import(href)));
  mq.load = loadOverlay();
  mq.loadScript = loadScript;
});
