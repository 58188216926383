// Simple deep merge:
//  - no loops!
//  - arrays replace hashes, hashes replace arrays
//  - hashes are merged, arrays are concatenated

export default function deepMerge(to, from) {
  // `to' is assumed to be hash; `from' could be hash or null
  if(from)
    Object.keys(from).forEach( propName => {
      const propTo = to[propName], propFrom = from[propName];
      if(Array.isArray(propTo) && Array.isArray(propFrom))
        propTo.push(...propFrom);
      else if(isProperObject(propTo) && isProperObject(propFrom))
        deepMerge(propTo, propFrom);
      else
        to[propName] = propFrom;
    } );

  return to;
}

function isProperObject(maybeObject) {
  return maybeObject && typeof maybeObject=='object' && !Array.isArray(maybeObject);
}
